<template>
  <div>
    <el-container>
      <!--侧边栏-->
      <el-aside :width="asideWidth" style="min-height: 100vh;background-color: #001529;">
        <div style="height: 60px;color:white;display: flex;align-items: center;justify-content: center">
         <img src="@/assets/logo1.png" style="width: 30px;" alt="">
          <span class="logo-title" v-show="!isCollapse">Ant Admin</span>
        </div>

        <el-menu router="" :collapse="isCollapse" :collapse-transition="false" background-color="#001529" text-color="#ffffffa6" active-text-color="#fff" style="border:none;" :default-active="$route.path">
          <el-menu-item index="/home">
              <i class="el-icon-s-home"></i>
              <span slot="title">系统主页</span>
          </el-menu-item>
          <el-submenu index="info" v-if="user.role === 'admin'">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>信息管理</span>
            </template>
            <el-menu-item index="/user">
                <i class="el-icon-user"></i>
                <span>用户信息</span>
            </el-menu-item>
            <el-menu-item index="/news">
              <i class="el-icon-info"></i>
              <span>新闻信息</span>
            </el-menu-item>
            <el-menu-item index="/notice">
              <i class="el-icon-info"></i>
              <span>系统公告</span>
            </el-menu-item>
            <el-menu-item index="/logs">
              <i class="el-icon-info"></i>
              <span>系统日志</span>
            </el-menu-item>
            <el-menu-item index="/charts">
              <i class="el-icon-info"></i>
              <span>图表统计</span>
            </el-menu-item>
            <el-menu-item index="/orders">
              <i class="el-icon-info"></i>
              <span>订单信息</span>
            </el-menu-item>
          </el-submenu>

        </el-menu>
      </el-aside>

      <el-container>
        <!--头部区域-->
        <el-header>
          <i :class="collapseIcon" style="font-size: 26px;" @click="handleCollapse"></i>
          <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left: 20px;">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path: $route.path}">{{$route.meta.name}}</el-breadcrumb-item>
          </el-breadcrumb>

          <div style="flex: 1;width: 0;display: flex;align-items: center;justify-content: flex-end;">
            <i class="el-icon-quanping" style="font-size: 26px;" @click="handleFull"></i>
            <el-dropdown placement="bottom">
              <div style="display: flex;align-items: center;cursor: default;">
                <img :src="user.avatar|| 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" alt="" style="width: 40px;height: 40px;margin: 0 5px;border-radius: 50%;">
                <span>{{user.name}}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$router.push('/person')">个人信息</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/password')">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
<!--        主体区域-->
        <el-main>
          <router-view @update:user="updateUser"/>
        </el-main>

      </el-container>

    </el-container>
  </div>
</template>
<style>
  .el-menu--inline{
    background-color: #000c17 !important;
  }
  .el-menu--inline .el-menu-item{
    background-color: #000c17 !important;
    padding-left: 47px !important;
  }
  .el-menu-item:hover, .el-submenu__title:hover{
    color: #fff !important;
  }
  .el-submenu__title:hover i{
    color: #fff !important;
  }
  .el-menu-item.is-active{
    background-color: #1890ff !important;
    border-radius:8px;
    width: 96%;
    margin: auto;
  }
  .el-tooltip,.el-menu-item{
    width: 96%;
    margin: auto;
  }
  .el-menu-item{
    height: 40px !important;
    line-height: 40px !important;
  }
  .el-submenu__title{
    height: 40px !important;
    line-height: 40px !important;
  }
  .el-submenu .el-menu-item{
    min-width: 0 !important;
  }
  .el-submenu__icon-arrow{
    margin-top: -5px !important;
  }
  .el-aside{
    transition: width .3s;
    box-shadow: 2px 0 6px rgba(0,21,41,.35);
  }
  .logo-title {
    margin-left: 5px;
    font-size: 20px;
    transition: all .3s;
  }
  .el-header{
    box-shadow: 2px 0 6px rgba(0,21,41,.35);
    display: flex;
    align-items: center;
  }
</style>
<script>
  import request from "@/utils/request";

  export default {
    name:'Manager',
    data() {
      return{
        user: JSON.parse(localStorage.getItem('userInfo') || '{}'),
        isCollapse:false,
        asideWidth:'200px',
        collapseIcon:'el-icon-s-fold'
      }
    },
    mounted() {
      if(!this.user.id){
        this.$router.push('/login')
      }
    },
    methods:{
      updateUser(user){//获取子组件传输的数据，跟新当前页面数据
        this.user=JSON.parse(JSON.stringify(user));//让父级对象跟子级的对象无关联
      },
      logout(){// 退出登录
        localStorage.removeItem("userInfo");//清除登录信息
        this.$router.push('/login');
      },
      handleFull(){//全屏
        document.documentElement.requestFullscreen()
      },
      handleCollapse(){//隐藏菜单
        this.isCollapse=!this.isCollapse;
        this.asideWidth=this.isCollapse ?'64px':'200px';
        this.collapseIcon=this.isCollapse ?'el-icon-s-unfold':'el-icon-s-fold';
      }
    },
  }
</script>