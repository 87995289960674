import Vue from 'vue'
import VueRouter from 'vue-router'
import Manager from '../views/Manager.vue'
import LoginView from '../views/Login.vue'
import RegisterView from '../views/Register.vue'

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'manager',
    component: Manager,
    redirect:'/home',
    children:[
      {path:'403',name:'Auth',meta:{name:'无权限'},component:()=>import('../views/Auth')},
      {path:'home',name:'Home',meta:{name:'系统首页'},component:()=>import('../views/manager/Home')},
      {path:'user',name:'User',meta:{name:'用户信息'},component:()=>import('../views/manager/User')},
      {path:'news',name:'News',meta:{name:'新闻信息'},component:()=>import('../views/manager/News')},
      {path:'newsDetail',name:'NewsDetail',meta:{name:'新闻详情'},component:()=>import('../views/manager/NewsDetail')},
      {path:'notice',name:'Notice',meta:{name:'系统公告'},component:()=>import('../views/manager/Notice')},
      {path:'logs',name:'Logs',meta:{name:'系统日志'},component:()=>import('../views/manager/Logs')},
      {path: 'charts',name: 'Charts',meta: {name: '图表统计'},component:()=>import('../views/manager/Charts')},
      {path: 'orders',name: 'Orders',meta: {name: '订单信息'},component:()=>import('../views/manager/Orders')},
      {path:'person',name:'Person',meta:{name:'个人信息'},component:()=>import('../views/manager/Person')},
      {path:'password',name:'Password',meta:{name:'修改密码'},component:()=>import('../views/manager/Password')}
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta:{name:'登录'},
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    meta:{name:'注册'},
    component: RegisterView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  //to 到达的路由信息
  //from 来源的路由信息
  //next 帮助跳转路由
  let adminPaths=['/user']
  let user=JSON.parse(localStorage.getItem('userInfo') || '{}');

console.log(user)
  // if(user.username){
  //   console.log(1111)
  // }else{
  //
  // }
if(user.role!=='admin' && adminPaths.includes(to.path)){//如果当前登录用户不是管理员，不允许调整管理员权限页面
    next('/403');
  }
  next()
})

export default router
