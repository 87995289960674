<template>
    <div style="height: 100vh;overflow:hidden;display:flex;align-items:center;justify-content:center;background-color: aqua;">
        <div style="display: flex;background-color: white;width:50%;border-radius: 6px;overflow: hidden;">
            <div style="flex: 1">
                <img src="@/assets/login.png" alt="" style="width: 100%;">
            </div>
            <div style="flex: 1;display:flex;align-items: center;justify-content: center;">
                <el-form :model="user" :rules="rules" ref="loginRef" style="width: 80%;">
                    <div style="font-size: 20px;font-weight: bold;text-align: center;margin-bottom: 20px;">欢迎登录后台管理系统</div>
                    <el-form-item prop="username">
                        <el-input prefix-icon="el-icon-user" size="medium" placeholder="请输入用户名" v-model="user.username"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input prefix-icon="el-icon-lock" size="medium" show-password placeholder="请输入密码" v-model="user.password"></el-input>
                    </el-form-item>
                    <el-form-item prop="validCode">
                        <div style="display: flex;">
                            <el-input prefix-icon="el-icon-circle-check" size="medium" placeholder="请输入验证码" v-model="user.validCode" style="flex: 1"></el-input>
                            <div style="flex: 1;height: 36px;" >
                                <valid-code @update:value="getCode"/>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" style="width: 100%;" @click="login">登 录</el-button>
                    </el-form-item>
                    <div style="display: flex">
                       <div style="flex: 1;"> 还没有账号？请 <span style="color:#0f9876;cursor: pointer" @click="$router.push('/register')">注册</span></div>
                        <div style="flex: 1;text-align: right;color:#0f9876;cursor: pointer;" @click="handleForgetPass">忘记密码</div>
                    </div>
                </el-form>
            </div>
        </div>

        <el-dialog title="忘记密码" :visible.sync="forgetPassDialogVis" width="30%">
            <el-form :model="forgetUserForm" label-width="80px" style="padding-right: 20px;">
                <el-form-item label="用户名" >
                    <el-input v-model="forgetUserForm.username" autocomplete="off" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" >
                    <el-input v-model="forgetUserForm.phone" autocomplete="off" placeholder="请输入手机号"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="forgetPassDialogVis = false">取 消</el-button>
                <el-button type="primary" @click="resetPassword">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import ValidCode from "@/components/ValidCode";

    export default {
        name: "Login",
        components:{
          ValidCode
        },
        data(){
            var validateCode = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入验证码'));
                } else if(value.toLowerCase()!=this.code){
                    callback(new Error('验证码错误'));
                }else{
                    callback();
                }
            };
            return{
                forgetPassDialogVis:false,
                forgetUserForm:{},
                code:'',
                user: {
                    username:'',
                    password:'',
                    validCode:''
                },
                rules:{
                    username:[
                        {required:true,message:' 请输入用户名',trigger:'blur'}
                    ],
                    password:[
                        {required:true,message:' 请输入密码',trigger:'blur'}
                    ],
                    validCode:[
                        {validator:validateCode,trigger:'blur'}
                    ]
                }
            }
        },
        created() {
        },
        methods:{
            handleForgetPass(){
                this.forgetUserForm={};
                this.forgetPassDialogVis=true;
            },
            resetPassword(){
                this.$request.put('/user/password',this.forgetUserForm).then(res=>{
                    if(res.code=='200'){
                        this.$message.success('重置成功')
                        this.forgetPassDialogVis=false;
                    }else{
                        this.$message.error(res.msg);
                    }
                })
            },
            login(){
                this.$refs['loginRef'].validate((valid) => {
                    if (valid) {
                        this.$request.post('/user/login',this.user).then(res=>{
                            console.log(res);
                            if(res.code=='200'){
                                this.$router.push('/')
                                this.$message.success('登录成功')
                                localStorage.setItem("userInfo", JSON.stringify(res.data))  // 存储用户数据
                            }else{
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getCode(code){
                console.log(code);
                this.code=code.toLowerCase();
            }
        }
    }
</script>

<style scoped>

</style>